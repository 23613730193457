import React from "react";
import styled from "styled-components";
import Device from "./media/Device";
import OrderListLink from "./parts/OrderListLink";
import Toc from "./parts/TableOfContents/Toc";

const Sidebar = ({ tocData }) => {
  const lists = [
    { title: "通常注文", link: "/how-to-order/normal-order" },
    { title: "通信販売", link: "/how-to-order/online-shop" },
  ];
  return (
    <StyledAside>
      <StyledSticky>
        <OrderListLink items={lists} />
        <StyledTocContainer>
          <Toc tocData={tocData} />
        </StyledTocContainer>
      </StyledSticky>
    </StyledAside>
  );
};
const StyledTocContainer = styled.div`
  display: none;
  @media ${Device.laptop} {
    display: block;
  }
`;

const StyledAside = styled.aside`
  width: 90vw;
  position: relative;
  margin: 0 auto;
  @media ${Device.laptop} {
    display: block;
    width: 50rem;
    margin-top: 3rem;
  }
`;
const StyledSticky = styled.div`
  @media ${Device.laptop} {
    position: sticky;
    top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 6rem;
  }
`;
export default Sidebar;

import React from "react";
import styled, { css } from "styled-components";
import Device from "../../media/Device";
import InternalLink from "./InternalLink";
import { SSidebarH2 } from "../../../styled/SText";
const Toc = ({ tocData: TOC, sidebarStyle, landscape, mobilemenu }) => {
  console.log(TOC);
  return (
    <>
      <SToc
        sidebarStyle={sidebarStyle}
        landscape={landscape}
        mobilemenu={mobilemenu}
      >
        <SSidebarH2 sidebarStyle={sidebarStyle} className="toc__title">
          目次
        </SSidebarH2>
        <div className="toc__container">
          <ol>
            {TOC.map((toc, index) => (
              <li key={toc.title + index} className="toc__h2Container">
                {/* components in same file */}
                <InternalLink toc={toc} index={index} num />

                {/*  styled in same file */}
                <SOl>
                  {toc.items &&
                    toc.items.map((item, itemIndex) => (
                      <li key={item + itemIndex}>
                        {/* components in same file */}
                        <InternalLink toc={item} index={itemIndex} />
                      </li>
                    ))}
                </SOl>
              </li>
            ))}
          </ol>
        </div>
        {/* {sidebarStyle && <div className="toc__borderBottom"></div>} */}
      </SToc>
    </>
  );
};

const SToc = styled.div`
  max-width: 460px;
  width: 100%;
  /* sidbarの目次と本文の目次スタイリング */
  ${({ sidebarStyle }) =>
    !sidebarStyle
      ? css`
          margin: 6rem auto;
          padding: 0 1rem;
          ${({ landscape }) =>
            landscape &&
            css`
              @media (orientation: landscape) and (max-width: 930px) and (max-width: 930px) {
                margin: 1rem auto;
                padding: 0 1rem;
              }
            `}
        `
      : css`
          /* display: none; */
          @media ${Device.laptop} {
            display: block;
          }
        `}

  ol {
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }
  .toc {
    &__container {
      ${({ sidebarStyle }) =>
        !sidebarStyle
          ? css`
              @media (orientation: landscape) and (max-width: 930px) and (max-width: 930px) {
                overflow-y: scroll;
                height: 70vh;
              }
            `
          : css`
              height: 35rem;
            `}
      ${({ mobilemenu }) =>
        mobilemenu &&
        css`
          height: 60vh;
        `}
    }
    &__h2Container {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
`;
const SOl = styled.ol`
  .hide {
    visibility: hidden;
  }
`;
export default Toc;

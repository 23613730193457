import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { SSidebarH2 } from "../../styled/SText";

const OrderListLink = ({ items }) => {
  return (
    <StyledOrderList>
      <SSidebarH2>ご注文方法</SSidebarH2>
      <ul className="orderList__lists">
        {items.map((item, index) => (
          <li className="orderList__item" key={item.title + index}>
            <Link className="orderList__link" to={item.link}>
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </StyledOrderList>
  );
};

const StyledOrderList = styled.div`
  ul {
  }
  li {
  }
  .orderList {
    &__lists {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    &__item {
    }
    &__link {
      display: block;
      padding: 1rem 0;
      transition: all 0.3s;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
export default OrderListLink;

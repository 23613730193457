import React from "react";
import { useSetRecoilState } from "recoil";
import styled, { css } from "styled-components";
import { tocbuttonIsOpen } from "../../../state/state";
import scrollTo from "gatsby-plugin-smoothscroll";

const InternalLink = ({ toc, index, num }) => {
  const onClickHandler = (url) => {
    setTocIsOpen(false);
    scrollTo(url);
  };
  const setTocIsOpen = useSetRecoilState(tocbuttonIsOpen);

  return (
    <SInternalLinkButton onClick={() => onClickHandler(toc.url)} num={num}>
      {/* <span>{toZenkaku(listNum)}</span> */}
      <span>{index + 1}-</span>
      {toc.title}
    </SInternalLinkButton>
  );
};

const h2ListTopSpace = "0.5rem";

const SInternalLinkButton = styled.button`
  color: var(--black-color);
  text-align: left;
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 3rem;
  letter-spacing: 0.5px;
  &:hover {
    text-decoration: underline;
  }
  ${({ num }) =>
    num
      ? css`
          font-size: 1.6rem;
          /* font-weight: 700; */
          padding: ${h2ListTopSpace} 0;
          line-height: 2.3rem;
        `
      : css`
          font-size: 1.4rem;
          padding: 3.5px 0;
        `}
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-align: center;
    margin-top: ${h2ListTopSpace};
    ${({ num }) =>
      num
        ? css`
            width: 2.4rem;
            height: 2.4rem;
            color: var(--black-color);
            /* color: var(--primary-color); */
            border-radius: 50%;
            top: 0;
            left: -3rem;
            font-size: 1.6rem;
          `
        : css`
            display: none;
          `}
  }
`;
export default InternalLink;

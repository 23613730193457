import React from "react";
import styled from "styled-components";
import Device from "./media/Device";
import MobileMenu from "./MobileMenu/MobileMenu";
import ToTopButton from "./parts/ToTopButton";
import Header from "./section/Header";
import Sidebar from "./Sidebar";
import bgImage from "../images/bg80.png";
import Footer from "./section/Footer";
import PageTitle from "./parts/PageTitile";
import PankuzuList from "./parts/PankuzuList";
// import PageTransitionAnimation from "./PageTransitionAnimation";
import TocButton from "./parts/TableOfContents/TocButton";

const LayoutForOrder = ({
  children,
  mainTitle,
  subTitle,
  panTitle,
  panLocation,
  tocData,
}) => {
  return (
    <>
      <TocButton tocData={tocData} />
      <Header />
      <MobileMenu />
      <PageTitle title={mainTitle} subTitle={subTitle} />
      <PankuzuList title={panTitle} location={panLocation} />
      <StyledContainerDiv>
        <StyledOrderDiv>
          <StyledOrderMain>{children}</StyledOrderMain>
          <Sidebar tocData={tocData} sidebarStyle />
        </StyledOrderDiv>
        <Footer />
      </StyledContainerDiv>
      <ToTopButton />
    </>
  );
};
const StyledContainerDiv = styled.div`
  /* background-image: url(${bgImage});
  background-position: center center;
  background-repeat: repeat;
  background-size: 200%;
  @media ${Device.tablet} {
    background-size: 120%;
  }
  @media ${Device.laptop} {
    background-size: 70%;
  } */
`;
const StyledOrderMain = styled.main`
  width: 90vw;
  margin: 0 auto 10rem;
  padding-top: 2rem;
  @media ${Device.laptop} {
    max-width: 72rem;
  }
`;

const StyledOrderDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 7rem;
  width: 90vw;
  max-width: var(--max-width);
  margin: 0 auto;
  margin-bottom: 10rem;
  @media ${Device.laptop} {
    flex-direction: row;
    gap: 6rem;
    margin-bottom: 10rem;
  }
`;
export default LayoutForOrder;

import React from "react";
import LayoutForOrder from "../../components/LayoutForOrder";
import { Seo } from "../../components/seo";
import { StyledOrderH2, StyledOrderP } from "../../styled/SText";

const NormalOrder = ({ location }) => {
  const toclist = [
    {
      title: "お問い合わせ",
      url: "#contact",
      // items: [
      //   { title: "アカウント登録", url: "#account" },
      //   { title: "クレジットカードの登録", url: "#creditCard" },
      // ],
    },
    {
      title: "日程・和菓子を決める",
      url: "#decide",
    },
    {
      title: "発送",
      url: "#shipping",
    },
  ];
  return (
    <>
      <LayoutForOrder
        mainTitle="通常注文"
        subTitle="normal order"
        panTitle="通常注文"
        panLocation={location}
        tocData={toclist}
      >
        {/* お問い合わせ */}
        <StyledOrderH2 id={toclist[0].url.slice(1)}>お問い合わせ</StyledOrderH2>
        <StyledOrderP>お問い合わせページより、必須項目を記入。</StyledOrderP>
        <StyledOrderP>
          お問い合わせ内容詳細欄につきましては、具体的な個数や和菓子の種類などを記載
        </StyledOrderP>
        <StyledOrderP>
          ギフト包装なしの場合、包装は簡易包装、箱はサービス箱となります。
        </StyledOrderP>

        {/* 日程・和菓子を決める */}
        <StyledOrderH2 id={toclist[1].url.slice(1)}>
          日程・和菓子を決める
        </StyledOrderH2>

        {/* 発送 */}
        <StyledOrderH2 id={toclist[2].url.slice(1)}>発送</StyledOrderH2>
        <StyledOrderP>
          クール冷凍便(ヤマト運輸)にて発送させていただきます。
        </StyledOrderP>
        <StyledOrderP>送料についてはこちら</StyledOrderP>
      </LayoutForOrder>
    </>
  );
};

export default NormalOrder;
export const Head = () => <Seo title="通常のご注文方法"></Seo>;

import React from "react";
import styled from "styled-components";
import { RiArrowRightSFill } from "react-icons/ri";
import { Link } from "gatsby";
const PankuzuList = ({ title, location: loca }) => {
  let List = [{ location: "/", title: "トップ" }];
  let positionNumber;
  return (
    <StyledPankuzuListNav>
      <ol itemScope itemType="https://schema.org/BreadcrumbList">
        {List.map((item, index, arr) => {
          positionNumber = arr.length;
          return (
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              key={item.location}
            >
              {index !== 0 && (
                <RiArrowRightSFill
                  className="icon"
                  aria-hidden="true"
                  title={index === 0 ? "一番上の階層は" : "次の階層は"}
                />
              )}
              <Link itemProp="item" to={item.location}>
                <span itemProp="name">{item.title}</span>
              </Link>
              <meta itemProp="position" content={index + 1} />
            </li>
          );
        })}
        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          key={loca.pathname}
        >
          <RiArrowRightSFill
            className="icon"
            aria-hidden="true"
            title="現在のページは"
          />
          <span itemProp="name" className="current-page">
            {title}
          </span>
          <meta itemProp="position" content={positionNumber} />
        </li>
      </ol>
    </StyledPankuzuListNav>
  );
};
const listPaddingLeft = " 0.5rem";
const StyledPankuzuListNav = styled.nav`
  .icon {
    margin: 0 2px 0 0;
  }
  .current-page {
    display: inline-block;
    padding: 1rem ${listPaddingLeft} 1rem calc(5px + ${listPaddingLeft});
  }
  ol {
    list-style: none;
    margin: 0 auto;
    max-width: var(--max-width);
    padding: 0.5rem 0;
    width: 90vw;
    li {
      display: inline-flex;
      align-items: center;
      font-size: 1.4rem;
      margin-right: 10px;
      color: var(--black-color);
      &:last-child {
        color: var(--primary-color);
        line-height: 1.6rem;
        text-decoration: underline;
      }
    }
    a {
      color: var(--black-color);
      padding: 1rem 0;
      line-height: 1.6rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
export default PankuzuList;

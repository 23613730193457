import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { mobileMenuIsOpen, tocbuttonIsOpen } from "../../../state/state";
import { SCircleButton } from "../../../styled/SButton";
import Toc from "./Toc";
import { BiListOl } from "react-icons/bi";
import Device from "../../media/Device";

const TocButton = ({ tocData }) => {
  /* state */
  const [tocIsOpen, setTocIsOpen] = useRecoilState(tocbuttonIsOpen);
  const mobileMenuIsOpenState = useRecoilValue(mobileMenuIsOpen);
  /* function */
  const onClickHandler = () => setTocIsOpen((pre) => !pre);
  console.log(mobileMenuIsOpenState);
  return (
    <>
      {tocIsOpen && (
        <StyledTocContainer>
          <Toc tocData={tocData} landscape mobilemenu />
        </StyledTocContainer>
      )}
      {/* mobile menuが開いているときは隠す */}
      {!mobileMenuIsOpenState && (
        <StyledTocButtonContainer
          aria-label="Table of Content"
          onClick={onClickHandler}
        >
          <BiListOl className="icon" />
        </StyledTocButtonContainer>
      )}
    </>
  );
};
const StyledTocContainer = styled.div`
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background-color: var(--white-color);
  z-index: var(--tocContainer-index);
`;
const StyledTocButtonContainer = styled(SCircleButton)`
  @media ${Device.laptop} {
    display: none;
  }
  position: fixed;
  bottom: 8rem;
  right: 2.5vw;
  z-index: var(--tocButton-index);
  background-color: var(--button-color);
  border: 1px solid var(--black-color);
  transition: all 0.3s;
  .icon {
    color: var(--black-color);
    font-size: 3rem;
    transition: all 0.3s;
  }
  &:hover {
    border: 1px solid var(--sub-color);
    .icon {
      color: var(--sub-color);
    }
  }
`;
export default TocButton;
